<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title" style="width: 500px">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="计价方式" prop="valuation_type">
        <el-radio-group v-model="info.valuation_type">
          <el-radio :label="item.id" v-for="(item,index) in typeList" :key="index">{{ item.title }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="区域">
        <el-button type="text" @click="addArea(0)">添加配送区域</el-button>
        <el-table
            :data="info.area"
            ref="rfTable"
            size="mini"
            border>
          <el-table-column
              prop="title"
              label="配送区域"
              width="250">
            <template slot-scope="scope">
              <el-button type="text" size="mini" v-if="scope.$index>0" @click="addArea(scope.$index)">编辑</el-button>
              {{scope.row.region_title}}
            </template>
          </el-table-column>
          <el-table-column
              prop="start_standard"
              :label="'首件（' + getUtil() + '）'"
              align="center"
              width="200">
            <template slot-scope="scope">
              <el-input v-model="scope.row.start_standard" placeholder="请输入首件"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="start_fee"
              label="首费（元）"
              align="center"
              width="200">
            <template slot-scope="scope">
              <el-input v-model="scope.row.start_fee" placeholder="请输入金额"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="add_standard"
              :label="'续件（' + getUtil() + '）'"
              align="center"
              width="200">
            <template slot-scope="scope">
              <el-input v-model="scope.row.add_standard" placeholder="请输入续件"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="add_fee"
              label="续费（元）"
              align="center"
              width="200">
            <template slot-scope="scope">
              <el-input v-model="scope.row.add_fee" placeholder="请输入金额"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
            <template slot-scope="scope">
              <el-popconfirm v-if="scope.$index>0"
                  title="确定要删除该记录吗？"
                  @confirm="delGroup(scope.$index)"
              >
                <el-button slot="reference" type="text" size="small">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
        title="选择城市"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="900px">
      <area-choose :selectedRegions="regionIds" :callFunc="chooseCity"></area-choose>
    </el-dialog>
  </div>
</template>
<script>

import areaChoose from "@/components/AreaChoose";
export default {
  data() {
    return {
      showModal: false,
      editIdx: 0,
      regionIds: [],
      typeList: [
        {id:1, title:'按重量'},
        {id:2, title:'按件数'},
        {id:3, title:'按体积'}
      ],
      info: {
        id: 0,
        title: '',
        valuation_type: 1,
        area: [{
          region: [],
          region_title: '默认运费',
          start_standard: '',
          start_fee: '',
          add_standard: '',
          add_fee: '',
        }]
      },
      avatar: [],
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        valuation_type: [
          { required: true, message: '请选择类别', trigger: 'blur' }
        ]
      }
    };
  },
  components: {
    areaChoose
  },
  created() {
    this.getMenuList()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getUtil() {
      if(this.info.valuation_type==1) {
        return 'kg'
      } else if(this.info.valuation_type==2) {
        return '个'
      } else if(this.info.valuation_type==3) {
        return 'm³'
      }
    },
    addArea(idx) {
      this.regionIds = this.info.area[idx].ids
      this.editIdx = idx
      this.showModal = true
    },
    chooseCity(res) {
      if(this.editIdx) {
        this.info.area[this.editIdx].region = res.cityIds
        this.info.area[this.editIdx].region_title = res.cityStrings
      } else {
        this.info.area.push({
          region:res.cityIds,
          region_title: res.cityStrings,
          start_standard: '',
          start_fee: '',
          add_standard: '',
          add_fee: '',
        })
      }
      this.showModal = false
    },
    delGroup(idx) {
      this.info.area.splice(idx,1)
    },
    getMenuList() {
      var that = this
      this.$api.news.newsMenuTree({},function (res) {
        if(res.errcode == 0) {
          that.menuList = res.data
        }
      })
    },
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.goods.shipmentInfo({id: that.info.id}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.pic) {
            that.avatar = [that.info.pic]
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.goods.shipmentEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
    fileChoose(item) {
      this.info.pic = item.pic
      this.avatar = [item.pic]
    }
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
  }
</style>
